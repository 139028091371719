// - - - - - - - - - - - - - - - - - -

// Header

// - - - - - - - - - - - - - - - - - -

header {
  // general
  display: grid;
  
  // for mobile
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: auto auto auto;  
  gap: 1rem 1rem;
  grid-template-areas: 
    "logo-top logo-top"
    "byline byline"
    "clients contact"; 
  margin: 0 0 5rem 0;

  // for desktop
  @include mq(tabletp) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    gap: 5rem 1rem;
    grid-template-areas:
      "logo-top byline . . clients clients clients contact";
  }
}

.contact { grid-area: contact; }
.clients { grid-area: clients; }
.logo { grid-area: logo-top; }
.byline { grid-area: byline; }

h2.logo {
  font-family: 'NeueHaasGroteskDisplay76BoldItalic';
  font-style: italic;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: -.075rem;
}

h2.logo a:active, h2.logo a:link, h2.logo a:visited {
  color: $text-colour;
  @media (prefers-color-scheme: dark) {
    color: $text-colour-dark;
  }
  text-decoration: none;
}

h2.logo a:hover {
  text-decoration: underline;
}

.clients h3 {
  font-weight: normal;
}

.clients ol {
  margin: 0;
  padding: 0 0 0 2rem;
  list-style-type: decimal-leading-zero;
}

.clients ol li {
  position: relative;
}

.clients ol li a + img {
  display: none;
}

.clients ol li a:hover + img {
  // for mobile
  display: none;
  
  // for desktop
  @include mq(tabletp) {
    display: block;
    position: absolute;
    top: 0;
    left: calc((((100vw - 4rem - 7rem)/8)*1) - 1rem);
    width: calc((((100vw - 4rem - 7rem)/8)*2) + 1rem);
    padding: 0;
    margin: 0;
  }
}

.clients ol li.active a {
  text-decoration: line-through;
}