img {
	margin: 0 auto 1rem 0;
}

figure img {
	margin: 0;
}

// Colours
$background-colour-dark: #000;
$text-colour-dark: #dddddd;
$background-colour: #fff;
$text-colour: #000;

// Imports
@import "_mixins";
@import "_fonts";
@import "_basics";

@import "_includes/_component__header";
@import "_includes/_component__footer";
@import "_pages/_page__project";

/*
// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
// @import "_plugins";



// - - - - - - - - - - Variables


// Colors

$background-color: #ffffff;
$background-alt-color: #f4f5f6;
$border-color: #00ffff;

$text-dark-color: #202224;
$text-medium-color: #6C7A89;
$text-light-color: #AEB3BB;

$accent-color: #00ffff;

$error-color: #D64541;


// Fonts

$body-font: Helvetica, "Ubuntu Mono", sans-serif;
$title-font: Helvetica, Rubik, sans-serif;

$regular-weight: 400;
$bold-weight: 700;


// Typography

$sitetitle-small: 24px;
$sitetitle-medium: 30px;
$sitetitle-large: 32px;
$sitetitle-weight: 400;
$sitetitle-spacing: 0em;

$menu-small: 30px;
$menu-medium: 15px;
$menu-large: 17px;
$menu-weight: 400;
$menu-spacing: 0em;

$h1-xl-size: 132px;
$h1-xl-size-small: 48px;
$h1-xl-lineheight: 96px;
$h1-xl-lineheight-small: 42px;

$h1-size: 48px;
$h1-size-small: 32px;
$h1-weight: 400;
$h1-spacing: 0px;
$h1-spacing-small: 0px;
$h1-lineheight: 48px;
$h1-lineheight-small: 32px;

$h2-size: 32px;
$h2-size-small: 24px;
$h2-weight: 400;
$h2-spacing: 0px;
$h2-spacing-small: 0px;
$h2-lineheight: 36px;
$h2-lineheight-small: 30px;

$h3-size: 32px;
$h3-size-small: 24px;
$h3-weight: 400;
$h3-spacing: 0px;
$h3-spacing-small: 0px;
$h3-lineheight: 36px;
$h3-lineheight-small: 30px;

$h4-size: 32px;
$h4-size-small: 28px;
$h4-weight: 400;
$h4-spacing: 0px;
$h4-spacing-small: 0px;
$h4-lineheight: 36px;

$h5-small: 17px;
$h5-medium: 20px;
$h5-large: 20px;
$h5-weight: 400;
$h5-spacing: 0em;
$h5-lineheight: 1.6;

$h6-small: 17px;
$h6-medium: 20px;
$h6-large: 20px;
$h6-weight: 400;
$h6-spacing: 0em;
$h6-lineheight: 1.6;

$subtitle-size: 32px;
$subtitle-size-small: 24px;
$subtitle-weight-regular: 400;
$subtitle-weight-heavy: 500;
$subtitle-spacing: 0px;
$subtitle-spacing-small: 0px;
$subtitle-lineheight: 40px;
$subtitle-lineheight-small: 32px;

$p-size: 14px;
$p-size-small: 14px;
$p-weight: 400;
$p-spacing: 0px;
$p-lineheight: 18px;

$p-small-size: 12px;
$p-small-size-small: 12px;
$p-small-weight: 400;
$p-small-spacing: 0px;
$p-small-lineheight: 16px;

$label-size: 12px;
$label-small: 12px;
$label-weight: 500;
$label-spacing: 0px;
$label-spacing-small: 0px;
$label-lineheight: 16px;
$label-lineheight-small: 16px;

$footnote-size: 13px;
$footnote-weight: 400;
$footnote-spacing: 0px;
$footnote-lineheight: 12px;

$blockquote-small: 14px;
$blockquote-medium: 14px;
$blockquote-large: 14px;
$blockquote-weight: 500;
$blockquote-spacing: 0em;
$blockquote-lineheight: 18;

$button-weight: 500;
$button-spacing: 0px;


// Logo
$logo-width: 100px;
$footer-logo-width: 100px;


// Post details
$grid-spacing: 10px;


// Base URL
$baseurl: '';


// - - - - - - - - - - Global styles

@import "_basic";
@import "_typography";
@import "_fonts";
@import "_buttons";
@import "_structure";


@import "_pages/_page";
@import "_pages/_page__blog";
@import "_pages/_page__post";
@import "_pages/_page__project";

@import "_includes/_component__header";
@import "_includes/_component__footer";

@import "_includes/_syntax";
// @import "_includes/_gallery";
@import "_includes/_component__contact-form";
@import "_includes/_component__post-card";
@import "_includes/_section__about";
@import "_includes/_section__recent-posts";



// // - - - - - - - - - - Section styles

@import "_includes/_section__listing";
@import "_includes/_section__hero"; */