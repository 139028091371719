// - - - - - - - - - - - - - - - - - -

// Footer

// - - - - - - - - - - - - - - - - - -

footer {
  // general
  display: grid;
  margin: 10rem 0 0 0;
  gap: 0 1rem;
  
  // for mobile
  grid-template-columns: 1fr 1fr; 
    grid-template-rows: auto auto auto auto; 
    grid-template-areas: 
      "logo-bottom logo-bottom"
      "byline-bottom byline-bottom"
      "info-bottom info-bottom"
      "about about"; 
  
  // for desktop
  @include mq(tabletp) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas:
      "logo-bottom byline-bottom info-bottom info-bottom info-bottom info-bottom info-bottom info-bottom"
      ". . . about about about about about";
  }
  
}

footer h2.logo {
  grid-area: logo-bottom;
}

footer img {
	max-width: 100%;
	grid-area: photo;
}

footer h3 {
	font-weight: normal;
	grid-area: headline;
}

footer div {
	grid-area: text;
}

footer .clients {
  grid-area: info-bottom;
  
  // for mobile
  margin: 2rem 0 5rem 0;
  
  // for desktop
  @include mq(tabletp) {
    margin: 0 0 5rem 0;
  }
}

footer .clients ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

footer .clients h2 {
  position: relative;
  font-weight: normal;
  margin: 0;
  
  // for mobile 
  font-size: 2rem;
  line-height: 2.25rem;
  
  // for desktop
  @include mq(tabletp) {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
 }

footer .clients h2 {
  /*text-indent: calc((((100vw - 4rem - 7rem)/8) * 1) + 1rem);*/
  font-family: NeueHaasGroteskDisplay;
  display: inline;
}

footer .clients h3 {
  font-weight: normal;
  line-height: 1.5;
  width: calc((((100vw - 4rem - 7rem)/8) * 1));
  text-align: right;
  margin: 0 1rem 0 0;
  padding: 0;
  
  // for desktop
  @include mq(tabletp) {
    float: left;
  }
}
footer .clients ul li {
padding: 0 0 .5rem 0;
}

footer .clients ul li p {
  // for mobile
  display: none;
  
  // for desktop
  @include mq(tabletp) {
    display: block;
    float: right;
  }
}

footer .clients ul li div {
  border-bottom: 1px solid $text-colour;
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid $text-colour-dark;
  }
  margin: 0;
  padding: 0 0 .5rem 0;
  
  // for desktop
  @include mq(tabletp) {
    margin: 0 0 0 calc((((100vw - 4rem - 7rem)/8) * 1) + 1rem);
  }
}

.byline-bottom {
  grid-area: byline-bottom;
}

.about p {
  // for mobile
  width: auto;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 3) + 2rem);
  }
}

.about {
  grid-area: about;
}

footer figcaption {
  // general
  font-size: .75rem;
  margin: 1rem 0;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 1));
    float: right;
  }
}

footer figcaption:before {
  content: "↳ ";
}