// - - - - - - - - - - - - - - - - - -

// Basics

// - - - - - - - - - - - - - - - - - -

html, body {
	font-size: 14px;
	line-height: 1.5;
	margin: 0 auto;
}

body {
	font-family: NeueHaasGroteskText;
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	margin: 2rem;
	background: $background-colour;
	color: $text-colour;
	@media (prefers-color-scheme: dark) {
		background: $background-colour-dark;
		color: $text-colour-dark;
	}

}

a:link, a:visited, a:active {
	color: $text-colour;
	@media (prefers-color-scheme: dark) {
		color: $text-colour-dark;
	}
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

section {
}

.hero, .hero p {
	position: relative;
	font-weight: normal;
	font-family: NeueHaasGroteskDisplay;
	
	// for mobile 
	font-size: 2rem;
	line-height: 2.25rem;
	
	// for desktop
	@include mq(tabletp) {
	font-size: 2.5rem;
	line-height: 2.75rem;
	}
 }

.hero, .hero p {
	/*text-indent: calc(((100vw - 4rem - 7rem)/8) + 1rem);*/
}

.hero h3 {
  // general
  font-weight: normal;
  float: left;
  line-height: 1.5;
  margin: 0 1rem 0 0;
  font-family: NeueHaasGroteskText;
  
  // for mobile
  width: calc((((100vw - 4rem - 7rem)/4) * 1) + .5rem);
  text-align: left;
  
  // for desktop
  @include mq(tabletp) {
  	width: calc((((100vw - 4rem - 7rem)/8) * 2) + 1rem);
	text-align: right;
  }
}

p, article, h2, h3 {
	margin-block-start: 0;
	margin-block-end: 1rem;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

h2, h3 {
	font-size: 1rem;
}

h3 {
}

figure {
	margin-block-start: 0;
	margin-block-end: 1rem;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	clear: both;
}

figure img {
	display:block;
}

:root {
	--plyr-color-main: #000;
	--plyr-captions-text-color: #000;
	--plyr-captions-background: rgba(255, 255, 255, 1);
	--plyr-video-controls-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}