// - - - - - - - - - - - - - - - - - -

// Project

// - - - - - - - - - - - - - - - - - -

.project-content {
  // for desktop
  @include mq(tabletp) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 5rem 1rem;
    grid-auto-flow: row;
    grid-template-areas:
      "info info info content content content content content";
  }
}

ul.project-info {
  list-style: none;
  margin: 0 0 1.75rem 0;
  padding: 0;
  
  // for desktop
  @include mq(tabletp) {
    grid-area: info;
    width: calc((((100vw - 4rem - 7rem)/8) * 3) + 2rem);
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
    align-self: start;
  }
}

ul.project-info li {
  border-top: 1px solid $text-colour;
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid $text-colour-dark;
  }
  padding: .25rem 0;
}

ul.project-info li h3 {
  float: left;
  font-weight: normal;
  margin: 0;
  
  // for mobile
  width: calc((((100vw - 4rem - 7rem)/4) * 1) + 1.5rem);
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 1) + 1rem);
  }
}

ul.project-info li p {
  font-weight: normal;
  
  // for mobile
  margin: 0 0 0 calc((((100vw - 4rem - 7rem)/4) * 1) + 1.5rem);
  
  // for desktop
  @include mq(tabletp) {
    margin: 0 0 0 calc((((100vw - 4rem - 7rem)/8) * 1) + 1rem);
  }
}

article {
  grid-area: content;
  /*margin: 0 0 0 calc((((100vw - 4rem - 7rem)/8) * 3) + 3rem);
  width: calc((((100vw - 4rem - 7rem)/8) * 3) + 2rem);*/
}

article p {
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 3) + 2rem);
  }
}

article img, article video {
  // for mobile
  max-width: 100%;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 5) + 4rem);
    max-width: calc((((100vw - 4rem - 7rem)/8) * 5) + 4rem);
  }
}

article h3 {
  font-weight: normal;
  margin-block-end: 1rem;
  margin-block-start: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: NeueHaasGroteskDisplay;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 3) + 2rem);
  }
}

.intro, .intro h2 {
  position: relative;
  font-weight: normal;
  
  // for mobile 
  font-size: 2rem;
  line-height: 2.25rem;
  
  // for desktop
  @include mq(tabletp) {
  font-size: 2.5rem;
  line-height: 2.75rem;
  }
 }

.intro {
  // for mobile
  margin: 0 0 5rem 0;
  
  // for desktop
  @include mq(tabletp) {
    margin: 0 0 5rem calc((((100vw - 4rem - 7rem)/8) * 2) + 2rem);
  }
}

.intro h2 {
  /*text-indent: calc((((100vw - 4rem - 7rem)/8) * 1) + 1rem);*/
  font-family: NeueHaasGroteskDisplay;
}

.intro h3 {
  font-weight: normal;
  float: left;
  line-height: 1.5;
  text-align: right;
  margin: 0 1rem 0 0;
  
  // for mobile
  width: calc((((100vw - 4rem - 7rem)/4) * 1) + .5rem);
  text-align: left;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 1));
    text-align: right;
  }
}

.intro h2 span {
  font-size: 1rem;
  line-height: 1.5;
}

.project-nav {
  // for mobile
  display: none;
  
  // for desktop
  @include mq(tabletp) {
    display: block;
    float: left;
  }
}

.project-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project-nav ul li {
  display: inline;
}

article blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  
  // for mobile
  margin-inline-start: calc((((100vw - 4rem - 7rem)/4) * 1) + 1.5rem);
  margin-inline-end: 0;
  
  // for desktop
  @include mq(tabletp) {
    margin-inline-start: calc((((100vw - 4rem - 7rem)/8) * .5));
    margin-inline-end: calc((((100vw - 4rem - 7rem)/8) * 2) + 2rem);
  }
}

article blockquote p {
  width: auto;
}

article figcaption {
  font-size: .75rem;
  margin: 1rem 0;
  
  // for desktop
  @include mq(tabletp) {
    width: calc((((100vw - 4rem - 7rem)/8) * 1));
    float: right;
  }
}

article figcaption:before {
  content: "↳ ";
}

article ul {
  list-style-type: circle;
  margin: 0;
  padding: 0 0 0 calc((((100vw - 4rem - 7rem)/8) * .5));
}
